import React, { useEffect, useRef } from 'react';
// 富文本 样式
import '../public/output.css';
// antd css
import "@/styles/globals.css";
// import * as Sentry from '@sentry/nextjs';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import Custom500 from './500';
import Head from 'next/head'
import type { AppProps } from 'next/app';
import withTheme from '@/theme/index';
import { App } from 'antd';
import { appWithTranslation } from 'next-i18next';
import type { NextPageWithLayout } from '@/types/App';
import nextI18nextConfig from '../next-i18next.config';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

// 指定网站支持切换的语言
const supportedPaths = ['en'];

// 定义网站App
function WebsiteApp(WebsiteAppProps: AppPropsWithLayout) {
  const { router, Component, pageProps } = WebsiteAppProps;
  // 解构页面属性
  const { navigationData = [], websiteInfo = [], ...restPageProps } = pageProps;
  // 解构路由属性
  const { locales, locale, asPath, events } = router;
  // 记录上个页面地址
  const previousUrlRef = useRef<string | null>(null);
  // 监听路由变化
  useEffect(() => {
    const handleRouteChange = () => {
      // 记录上个页面的地址
      previousUrlRef.current = asPath || null;
    };

    // 在客户端路由变化时记录上个页面地址
    events.on('routeChangeComplete', handleRouteChange);

    // 清理函数，在组件卸载时移除监听器
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  // 获取页面布局 getLayout 如果页面没有定义则使用默认布局 即：页面本身
  const getLayout = Component.getLayout || ((Page, layoutProps, pageProps) => <Page {...pageProps} {...layoutProps} />);

  return (
    // 错误边界
    <ReactErrorBoundary
      fallback={<Custom500 />}
      onReset={() => {
        // 重置错误边界的状态
        console.log('Error boundary reset');
      }}
    >
      {/* 设置viewport */}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property='locale' content={locale} />
        <meta property="og:locale" content={locale} />

        {locales?.map((loc) => (
          supportedPaths.includes(asPath) &&
          <link
            key={loc}
            rel="alternate"
            hrefLang={loc}
            href={`/${loc}${asPath}`}
          />
        ))}
      </Head>
      {/* 使用antd的App组件包裹，设置全局样式 第二个参数是设置语言 */}
      {withTheme(
        <App
          style={{ height: '100%' }}
          message={{
            maxCount: 3,
          }}
        >
          {/* 如果页面设置了布局函数，则执行布局函数 */}
          {getLayout(Component, { navData: navigationData, websiteInfo }, restPageProps)}
        </App>,
        locale || 'zh'
      )}

    </ReactErrorBoundary>
  );
}
// 使用next-i18next的appWithTranslation包裹
export default appWithTranslation(WebsiteApp, nextI18nextConfig);