/** @type {import('next-i18next').UserConfig} */
 module.exports = {
  i18n: {
    defaultLocale: 'zh',
    locales: ['en', 'zh'],
    localeDetection: false, // 自动检测语言
  }, // 语言配置
  // localePath: './public/locales', // 语言文件目录
  localeStructure: '{{lng}}/{{ns}}', // 语言文件目录结构
  localeExtension: 'json', // 语言文件扩展名
  ns: ['common', 'conference'], // 命名空间
  defaultNS: 'common', // 默认命名空间
};