import React from "react";
import { ConfigProvider } from "antd";
import theme from './themeConfig';
import zhCN from "antd/locale/zh_CN";
import enUS from 'antd/lib/locale/en_US';

const withTheme = (node: JSX.Element, locale: string) => (
    <ConfigProvider locale={locale === 'zh' ? zhCN: enUS} theme={theme}>
        {node}
    </ConfigProvider>
)

export default withTheme;