// theme/themeConfig.ts
import type { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  // 全局主题修改
  components: {
    Message: {
      zIndexPopup: 10000,
    },
  },
};

export default theme;
